import { Homepage } from '../kentico-types/homepage'
import { RootWidgetCarsCarousel as WidgetCarsCarousel } from '../src/widget-cars-carousel/RootWidgetCarsCarousel'
import { getContent, getEquipment } from '../lib/kenticoClient'
import { getTranslation } from '../src/splus-lib/utils/languageUtils'
import BottomBoxes from '../src/home/BottomBoxes'
import Head from 'next/head'
import PageWrapper from '../src/wrappers/PageWrapper'
import React from 'react'
import TopBanner from '../src/home/TopBanner'
import WhatMeansSkodaPlus from '../src/home/WhatMeansSkodaPlus'
import WidgetBasicFilter from '../src/widget-basic-filter/index'
import WidgetRecommendedModels from '../src/widget-recommended-models'
import useGTMPlaceholders from '../src/splus-lib/utils/hooks/useGTMPlaceholders'

type Props = {
  content: Homepage
  linkedContent: Record<string, any>
  equipmentData: any
}

const Home = (props: Props) => {
  const { content, linkedContent, equipmentData } = props

  useGTMPlaceholders({})
  if (!content) {
    return <div />
  }

  return (
    <PageWrapper footerTopOverlay hasBackground={true}>
      <Head>
        <title>
          {getTranslation('homepage.title') + ' - ' + getTranslation('general.skodaPlus')}
        </title>
      </Head>

      <div className='skoda-plus-hp-layout-template' id='main-content' role='main'>
        <div className='portlet-layout'>
          <div className='saps-hp-layout-top-row'>
            <div className='saps-hp-layout-top-row__col-left' id='column-2'>
              <TopBanner content={content} />
            </div>
            <div className='saps-hp-layout-top-row__col-right' id='column-3'>
              <div className='saps-hp-layout-top-row__col-right-content' id='hpContent'>
                <WidgetBasicFilter basicView={true} equipmentData={equipmentData} />
              </div>
            </div>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              viewBox='0 0 476 104'
              height='394'
              width='1800'
              className='saps-hp-layout-polygons'
            >
              <g fillRule='evenodd'>
                <path d='M476 0L0 76v28h476z' className='saps-hp-layout-polygon1' />
                <path d='M189 46l287 58V0z' className='saps-hp-layout-polygon2' />
              </g>
            </svg>
          </div>
          <div className='portlet-column portlet-column-only' id='column-1'>
            <WidgetRecommendedModels data={{ title: content.recommendationTitle.value }} />
            <WhatMeansSkodaPlus content={content} linkedContent={linkedContent} />
            <WidgetCarsCarousel />
            <BottomBoxes content={content} linkedContent={linkedContent} />
          </div>
        </div>
      </div>
    </PageWrapper>
  )
}

export default Home

export async function getStaticProps() {
  const data = await getContent('homepage')
  const equipmentData = await getEquipment()
  return {
    props: {
      content: data.content,
      linkedContent: data.linkedContent,
      equipmentData: equipmentData,
    },
  }
}
