import { API_URL, COUNTRY_CODE, LANGUAGE } from '../splus-lib/utils/languageUtils'
import { CARS_CAROUSEL_FRAGMENT, CarsCarousel } from './CarsCarousel'
import {
  RootWidgetCarsCarouselQueryQuery,
  RootWidgetCarsCarouselQueryQueryVariables,
} from './RootWidgetCarsCarousel.__generated__'
import { useComponentDidMount } from '../splus-lib/utils/hooks/hooks'
import React, { useState } from 'react'
import request, { gql } from 'graphql-request'

type Props = {
  alternativeDrives?: boolean
  fuelTypes?: any
}

export const RootWidgetCarsCarousel = ({ alternativeDrives, fuelTypes }: Props) => {
  const [data, setData] = useState(null as null | RootWidgetCarsCarouselQueryQuery)
  const [error, setErrors] = useState(null as null | string)

  // How can one be sure
  const petrolID = 'fuel_type_1'
  const dieselID = 'fuel_type_2'

  useComponentDidMount(() => {
    //Set filter variable of Gql query
    let pageSpecificGqlCarFilterInput = null as any

    if (alternativeDrives) {
      pageSpecificGqlCarFilterInput = {
        fuelTypes: fuelTypes
          .map((ft: any) => ft.id)
          .filter((ft: any) => ft !== petrolID && ft !== dieselID),
        regionCountry: COUNTRY_CODE,
      }
    } else {
      pageSpecificGqlCarFilterInput = {
        showOnHomepage: true,
        regionCountry: COUNTRY_CODE,
      }
    }

    const main = async () => {
      try {
        const data = await request<
          RootWidgetCarsCarouselQueryQuery,
          RootWidgetCarsCarouselQueryQueryVariables
        >(
          API_URL,
          gql`
            query RootWidgetCarsCarouselQuery(
              $filter: CarFilterInput
              $lang: Lang!
              $carImageLimit: Int!
              $first: Int!
            ) {
              ...CarsCarousel
            }
            ${CARS_CAROUSEL_FRAGMENT}
          `,
          {
            filter: pageSpecificGqlCarFilterInput,
            lang: LANGUAGE,
            carImageLimit: 2,
            first: 5,
          }
        )

        setData(data)
      } catch (err) {
        setErrors(err as any)
      }
    }
    main()
  })

  if (error) {
    return null
  } else if (data) {
    return <CarsCarousel data={data} />
  }
  return null
}
