import { Homepage } from '../../kentico-types/homepage'
import React from 'react'
import markdownToHtml from '../../lib/markdownToHtml'
import styled from 'styled-components'

const DivTopBannerText = styled.div`
  h2 {
    font-size: 35px;
    font-weight: bold;
    line-height: 1.1;
    color: #ffffff;
    margin-bottom: 10px;
    text-align: center;

    @media (min-width: 768px) {
      font-size: 45px;
      margin-bottom: 0px;
      text-align: left !important;
    }
  }
`

type Props = {
  content: Homepage
}

const TopBanner = ({ content }: Props) => {
  const TopBg = content.topBannerPicture.value[0].url

  return (
    <div
      className='saps-hp-banner-wrapper saps-h100 saps-claim-box saps-claim-box--top-banner'
      style={{ backgroundPosition: '70% bottom', backgroundImage: `url(${TopBg})` }}
    >
      <div className='d-flex d-md-block align-items-end saps-h100'>
        <div
          className='container d-block d-md-none'
          style={{
            position: 'absolute',
            top: '45px',
            textAlign: 'center',
            margin: '0 auto',
            left: '0',
            right: '0',
          }}
        >
          <div className='row'>
            <div className='col-12 col-md-7 col-lg-12' style={{ textAlign: 'center' }}>
              <DivTopBannerText>{markdownToHtml(content.topBannerText.value)}</DivTopBannerText>
            </div>
          </div>
        </div>
        <div
          className='container d-block d-md-none'
          style={{
            position: 'absolute',
            bottom: '25px',
            textAlign: 'center',
            margin: '0 auto',
            left: '0',
            right: '0',
          }}
        >
          <div className='row'>
            <div className='col-12 col-md-7 col-lg-12' style={{ textAlign: 'center' }}>
              <a
                href={content.topBannerButtonUrl.value}
                className='saps-button saps-button--with-icon-left'
              >
                {markdownToHtml(content.topBannerButtonText.value)}
              </a>
            </div>
          </div>
          <a
            href='#hpContent'
            className='js-scroll-to text-center d-block d-md-none saps-pt-20 saps-pb-20'
          >
            <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 341 191' height='17' width='30'>
              <path
                d='M337 24.5L170.5 191 4 24.5Q0 20 0 14.25T4 4q4-4 10-4t10 4l146.5 146.5L317 4q4-4 10-4t10 4q4 4 4 9.75t-4 10.75z'
                fill='#ef7d00'
              />
            </svg>
          </a>
        </div>
        <div className='container d-none d-md-block'>
          <div className='d-none d-xl-block' style={{ height: '68px' }} />
          <div className='d-none d-lg-block d-xl-none' style={{ height: '45px' }} />
          <div className='d-none d-md-block d-lg-none' style={{ height: '30px' }} />
          <div className='row'>
            <div className='col-12 col-md-7 col-lg-12' style={{ textAlign: 'center' }}>
              <DivTopBannerText>{markdownToHtml(content.topBannerText.value)}</DivTopBannerText>
              <a
                href={content.topBannerButtonUrl.value}
                className='saps-button saps-button--with-icon-left float-md-right'
              >
                {markdownToHtml(content.topBannerButtonText.value)}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default TopBanner
