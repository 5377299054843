import { Homepage } from '../../kentico-types/homepage'
import { PictureWithTextAndLink } from '../../kentico-types/picture_with_text_and_link'
import React from 'react'
import markdownToHtml from '../../lib/markdownToHtml'
import styled from 'styled-components'

const DivBannerText = styled.div`
  h3 {
    color: #ffffff;
    font-size: 35px;
    font-weight: bold;
    line-height: 1.09;
    margin-bottom: 20px;

    @media (min-width: 768px) {
      font-size: 45px;
      line-height: 0.93;
    }
  }
`

const DivBannerTextRight = styled.div`
  h3 {
    color: #ffffff;
    font-size: 35px;
    font-weight: bold;
    line-height: 1.09;
    margin-bottom: 20px;
    display: none;

    @media (min-width: 768px) {
      font-size: 45px;
      line-height: 0.93;
    }

    @media (min-width: 992px) {
      display: block;
    }
  }
`

const DivBottomBannerTextDesktop = styled.div`
  h3 {
    line-height: 1.2;
    margin-top: 80px;
    margin-bottom: 140px;
    font-size: 47px;
    color: #ffffff;
    font-weight: bold;
    display: none;

    @media (min-width: 992px) {
      display: block;
    }
  }
`

const DivBottomBannerTextMobile = styled.div`
  h3 {
    font-size: 35px;
    font-weight: bold;
    line-height: 1.2;
    color: #ffffff;
    margin-bottom: 20px;
    margin-top: 80px;
    display: block;

    @media (min-width: 768px) {
      font-size: 45px;
    }
    @media (min-width: 992px) {
      display: none;
    }
  }
`

type Props = {
  content: Homepage
  linkedContent: Record<string, PictureWithTextAndLink>
}

const BottomBoxes = ({ content, linkedContent }: Props) => {
  const banners = content.bottomBannerBanners.linkedItemCodenames.map(b => {
    return linkedContent[b]
  })

  if (banners.length !== 3) {
    return <div />
  }

  const TopLeftBackground = banners[0].picture.value[0].url
  const TopRightBackground = banners[1].picture.value[0].url
  const BottomBackground = banners[2].picture.value[0].url
  return (
    <div className='row no-gutters saps-hp-bottom-boxes saps-mt-20 saps-mt-70-md saps-mt-175-lg'>
      <div className='col-12 col-xl'>
        <div
          className='saps-claim-box text-center text-md-left saps-pt-70-md saps-pb-40 saps-h100'
          style={{
            backgroundImage: `url(${TopLeftBackground})`,
            backgroundPosition: '70% center',
            paddingTop: '30px',
            paddingLeft: '15px',
            paddingRight: '15px',
          }}
        >
          <div className='d-md-none' style={{ height: '180px' }} />
          <div className='d-flex no-gutters justify-content-center'>
            <div className='col-12 col-md-8'>
              <DivBannerText>{markdownToHtml(banners[0].text.value)}</DivBannerText>
              <a href={banners[0].url.value} className='saps-button saps-button--with-icon-left'>
                {markdownToHtml(banners[0].linkDescription.value)}
              </a>
            </div>
          </div>
          <div className='d-none d-md-block' style={{ height: '300px' }} />
        </div>
      </div>
      <div className='col-12 col-xl'>
        <div
          className='saps-claim-box text-center text-md-left saps-pt-70-md saps-pb-40 saps-h100'
          style={{
            backgroundImage: `url(${TopRightBackground})`,
            backgroundPosition: '70% center',
            paddingTop: '30px',
            paddingLeft: '15px',
            paddingRight: '15px',
          }}
        >
          <div className='d-md-none' style={{ height: '180px' }} />
          <div className='d-flex no-gutters justify-content-center'>
            <div className='col-12 col-md-8'>
              <DivBannerText>{markdownToHtml(banners[1].text.value)}</DivBannerText>

              <a href={banners[1].url.value} className='saps-button saps-button--with-icon-left'>
                {markdownToHtml(banners[1].linkDescription.value)}
              </a>
            </div>
          </div>
          <div className='d-none d-md-block' style={{ height: '300px' }} />
        </div>
      </div>

      <div className='col-12'>
        <div
          className='saps-claim-box text-center text-md-left saps-pt-70-md saps-pb-70'
          style={{
            backgroundImage: `url(${BottomBackground})`,
            backgroundPosition: 'center 60%',
            backgroundSize: 'cover',
            minHeight: '430px',
            paddingTop: '30px',
            paddingLeft: '15px',
            paddingRight: '15px',
          }}
        >
          <div className='bottom-banner-wrapper d-flex no-gutters justify-content-end'>
            <div className='col-12 col-lg-6'>
              <div className='d-flex no-gutters justify-content-center'>
                <div className='col-12 col-md-10'>
                  <DivBottomBannerTextDesktop>
                    {markdownToHtml(content.bottomBannerText.value)}
                  </DivBottomBannerTextDesktop>
                  <DivBottomBannerTextMobile>
                    {markdownToHtml(content.bottomBannerTextMobile.value)}
                  </DivBottomBannerTextMobile>
                </div>
              </div>
            </div>
            <div className='col-12 col-lg-6'>
              <div className='d-flex no-gutters justify-content-center'>
                <div className='col-12 col-md-8'>
                  <DivBannerTextRight>{markdownToHtml(banners[2].text.value)}</DivBannerTextRight>
                  <a
                    href={banners[2].url.value}
                    className='saps-button saps-button--with-icon-left'
                  >
                    {markdownToHtml(banners[2].linkDescription.value)}
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className='d-none d-md-block' style={{ height: '340px' }} />
        </div>
      </div>
    </div>
  )
}
export default BottomBoxes
