import { A11y, Navigation, Pagination, Scrollbar } from 'swiper/modules'
import { CAR_FRAGMENT } from '../splus-lib/components/Car'
import { Car } from '../splus-lib/components/Car'
import { CarsCarouselFragment } from './CarsCarousel.__generated__'
import { Swiper, SwiperSlide } from 'swiper/react'
import { gql } from 'graphql-request'
import { noModulo } from '../splus-lib/utils'
import { useInView } from 'react-intersection-observer'
import React, { useEffect, useState } from 'react'

export const SliderArrow = ({ ...buttonProps }) => (
  <button {...buttonProps}>
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='20'
      height='40'
      viewBox='0 0 256 512'
      className='saps-cars-carousel__arrow-icon'
    >
      {' '}
      <path
        d='M194.5 419L28 252.5 194.5 86q4.5-4 10.25-4T215 86q4 4 4 10t-4 10L68.5 252.5 215 399q4 4 4 10t-4 10-9.75 4-10.75-4z'
        className='saps-cars-carousel__arrow-icon-path'
      />{' '}
    </svg>
  </button>
)

type Props = {
  data: CarsCarouselFragment | null | undefined
}

export const CarsCarousel = (props: Props) => {
  const [alreadyWasInView, setAlreadyWasInView] = useState(false)
  const edges = props.data?.cars.edges
  const [ref, inView] = useInView()

  useEffect(() => {
    if (alreadyWasInView === false) {
      setAlreadyWasInView(true)
    }
  }, [inView])

  /**
   * `<Swipper ... />` `lazy` is set to `false` because 2 nested Swipers does not works
   * example:
   * ```javascript
   * <Swipper loop={true} >...<Swipper lazy={true} /> </Swipper>
   * ```
   *
   * problem is that `react-id-swiper` is just react wrapper of jQuery library.
   *
   * When we set `<Swiper loop={true}` internally in library are created cloned items for better performance.
   * These cloned items are not `React.elements` and all events and React *API* does not work for them
   *
   * It means that when we need to handle lazy load of Car images
   * we have to get access to instances but we can't because of jQuery...
   *
   * best solution could be to use different React slider/carousel which will works correctly
   */
  return (
    <div ref={ref} className='saps-cars-carousel__wrapper'>
      {edges && (inView || alreadyWasInView) && (
        <Swiper
          modules={[
            Navigation,
            //  Pagination, Scrollbar, A11y
          ]}
          // spaceBetween={40}
          // slidesPerView={'auto'}
          // loop={true}
          // centeredSlides={true}
          // initialSlide={undefined} // Calculate your initial slide value
          // breakpoints={{
          //   1366: {
          //     allowTouchMove: true,
          //   },
          //   768: {
          //     autoplay: false,
          //   },
          //   560: {
          //     autoplay: {
          //       enabled: true,
          //       delay: 4000,
          //       disableOnInteraction: false,
          //     },
          //   },
          // }}
          // navigation
          // pagination={{ clickable: true }}

          // activeSlideKey={undefined}

          allowTouchMove={false}
          navigation={{
            nextEl: '.saps-carousel-next',
            prevEl: '.saps-carousel-prev',
          }}
          // pagination={{
          //   el: '.swiper-pagination',
          //   type: 'bullets',
          //   clickable: true,
          // }}
          // navigation={{
          //   nextEl: '.saps-carousel-next',
          //   prevEl: '.saps-carousel-prev',
          // }}
          loop
          // onNavigationNext={}
          // renderPrevButton={() => <SliderArrow className='saps-carousel-prev' />}
          // renderNextButton={() => <SliderArrow className='saps-carousel-next' />}
          spaceBetween={40}
          // centeredSlides
          slidesPerView={'auto'}
          // slidesPerView={2}
          initialSlide={noModulo(edges.length, 2)}
          breakpoints={{
            1366: {
              allowTouchMove: true,
            },
            768: {
              autoplay: false,
            },
            560: {
              autoplay: {
                // enabled: true,
                delay: 4000,
                disableOnInteraction: false,
              },
            },
          }}
        >
          <SliderArrow className='saps-carousel-prev' />
          <SliderArrow className='saps-carousel-next' />

          {edges.map(car => (
            <SwiperSlide key={`cars-carousel-${car?.node?.id}`} style={{ maxWidth: 320 }}>
              <div key={`cars-carousel-${car?.node?.id}`}>
                <Car car={car?.node} showComparisonButton={false} lazy={false} />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      )}
    </div>
  )
}

export const CARS_CAROUSEL_FRAGMENT = gql`
  fragment CarsCarousel on Query {
    cars(first: $first, filter: $filter) {
      edges {
        node {
          id
          ...Car_car
        }
      }
    }
  }
  ${CAR_FRAGMENT}
`
