import { Homepage } from '../../kentico-types/homepage'
import { PictureWithTextAndDescription } from '../../kentico-types/picture_with_text_and_description'
import React from 'react'
import markdownToHtml from '../../lib/markdownToHtml'
import styled from 'styled-components'

const DivTitle = styled.div`
  h2 {
    font-size: 35px;
    font-weight: bold;
    line-height: 1.09;
    color: #000000;
    margin-bottom: 30px;

    @media (min-width: 768px) {
      font-size: 45px;
      line-height: 0.93;
    }
  }
`

const DivSubTitle = styled.div`
  p {
    margin-bottom: 30px;
    font-size: 15px;
    line-height: 1.6;

    @media (min-width: 992px) {
      margin-bottom: 40px;
    }
  }
`

const DivAdvantageTitle = styled.div`
  p {
    font-size: 18px;
    font-weight: bold;
    line-height: 1.23;
    margin: 0;

    @media (min-width: 768px) {
      font-size: 20px;
      line-height: 1.3;
    }
  }
`
const DivAdvantageHiddenTitle = styled.div`
  p {
    font-size: 15px;
    line-height: 1.6;
    margin: 0;

    @media (min-width: 768px) {
      font-weight: bold;
    }
  }
`

const ImgAdvantageImage = styled.img`
  display: inline !important;
`

type Props = {
  content: Homepage
  linkedContent: Record<string, PictureWithTextAndDescription>
}

const WhatMeansSkodaPlus = ({ content, linkedContent }: Props) => {
  const renderSkodaPlusAdvantages = () => {
    return content.skodaplusAdvantages.linkedItemCodenames.map((c, i) => {
      const item = linkedContent[c]
      const Picture = item.picture.value[0].url

      return (
        <React.Fragment key={i}>
          <React.Fragment>
            <div className='col-12 col-md-6 col-lg saps-mb-20 saps-has-hover-content'>
              <div className='text-center'>
                <div className='d-flex flex-wrap no-gutters'>
                  <div className='col-12 order-md-2 saps-mb-15 saps-mb-0-md'>
                    <DivAdvantageTitle>{markdownToHtml(item.title.value)}</DivAdvantageTitle>
                  </div>
                  <div className='col-12 order-md-1 saps-mb-15-md'>
                    <ImgAdvantageImage src={Picture} alt={item.picture.value[0].name} />
                  </div>
                </div>
                <div className='saps-slick-mobile-with-arrows__dost-placeholder' />
              </div>
              <div className='saps-hover-content saps-hover-content--visible-on-mobile saps-p-20 saps-bg-grey-50'>
                <div className='saps-h100 d-flex justify-content-center align-items-center'>
                  <DivAdvantageHiddenTitle>
                    {markdownToHtml(item.description.value)}
                  </DivAdvantageHiddenTitle>
                </div>
              </div>
            </div>
          </React.Fragment>
        </React.Fragment>
      )
    })
  }

  return (
    <div className='container text-center saps-mt-50 saps-mb-50 saps-mb-70-md'>
      <div className='row no-gutters justify-content-center'>
        <div className='col-12 col-xl-10'>
          <DivTitle>{markdownToHtml(content.skodaplusTitle.value)}</DivTitle>
          <DivSubTitle>{markdownToHtml(content.skodaplusText.value)}</DivSubTitle>
          <div className='saps-slick-mobile-with-arrows'>
            <button
              data-href='#slickWhatDoesSPlusMean'
              className='js-slick-prev saps-slick-mobile-with-arrows__arrow
              saps-slick-mobile-with-arrows__arrow--next-to-icon
              saps-slick-mobile-with-arrows__arrow--prev'
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='20'
                height='40'
                viewBox='0 0 256 512'
                className='slick-mobile-with-arrows__arrow-icon'
              >
                <path
                  d='M194.5 419L28 252.5 194.5 86q4.5-4 10.25-4T215 86q4 4 4 10t-4 10L68.5 252.5 215
                  399q4 4 4 10t-4 10-9.75 4-10.75-4z'
                  className='slick-mobile-with-arrows__arrow-icon-path'
                />
              </svg>
            </button>
            <div className='row saps-slick-mobile-with-arrows__slider' id='slickWhatDoesSPlusMean'>
              {renderSkodaPlusAdvantages()}
            </div>
            <div className='saps-slick-mobile-with-arrows__dots saps-slick-mobile-with-arrows__dots--under-icon' />
            <button
              data-href='#slickWhatDoesSPlusMean'
              className='js-slick-next saps-slick-mobile-with-arrows__arrow
              saps-slick-mobile-with-arrows__arrow--next-to-icon saps-slick-mobile-with-arrows__arrow--next'
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='20'
                height='40'
                viewBox='0 0 256 512'
                className='slick-mobile-with-arrows__arrow-icon'
              >
                <path
                  d='M52.5 419L219 252.5 52.5 86q-4.5-4-10.25-4T32 86q-4 4-4 10t4
                  10l146.5 146.5L32 399q-4 4-4 10t4 10q4 4 9.75 4t10.75-4z'
                  className='slick-mobile-with-arrows__arrow-icon-path'
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
export default WhatMeansSkodaPlus
