import { CULTURE } from '../languageUtils'
import { gtmDataLayerPush, gtmLeid } from '../googleTagManager/utils'
import { props } from 'ramda'
import { useComponentDidMount } from './hooks'
import { useEffect } from 'react'

interface IPlaceholders {
  dealerId?: string | null
  carId?: string | null
}

export const useGTMPlaceholders = (placeholders: IPlaceholders) => {
  useComponentDidMount(() => {
    // Timeout to be able changed document.title in time
    setTimeout(() => {
      gtmDataLayerPush({
        event: 'registerVPLocalImporter',
        BID: gtmLeid,
        'page.LanguageVersion': CULTURE.toLowerCase(),
        'page.name': document.title,
        'web.environment': process.env.GTM_WEB_ENV,
        ...(!!placeholders.dealerId || !!placeholders.carId
          ? {
              market: {
                ...(!!placeholders.dealerId
                  ? { Placeholder1: `260-${placeholders.dealerId}` }
                  : {}),
                ...(!!placeholders.carId
                  ? { Placeholder2: placeholders.carId.replace(/Car-/g, '') }
                  : {}),
              },
            }
          : {}),
      })
    }, 500)
  })
  return
}

export default useGTMPlaceholders
